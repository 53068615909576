import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const BaigurImageOne = () => {
    return (
        <div className="h-full w-full">
            <StaticImage
                src={"../../../static/case-study/saigon-baigur/slider-1.png"}
                alt="Saigon Baigur mockup"
                objectFit="cover"
                className="hidden h-full w-full lg:block"
            />

            <StaticImage
                src={
                    "../../../static/case-study/saigon-baigur/slider-1-mobile.png"
                }
                alt="Saigon Baigur mockup"
                objectFit="cover"
                className="h-full w-full lg:hidden"
            />
        </div>
    )
}
const BaigurImageTwo = () => {
    return (
        <div className="h-full w-full">
            <StaticImage
                src={"../../../static/case-study/saigon-baigur/slider-2.png"}
                alt="Saigon Baigur mockup"
                objectFit="cover"
                className="h-full w-full"
            />
            <StaticImage
                src={
                    "../../../static/case-study/saigon-baigur/slider-2-mobile.png"
                }
                alt="Saigon Baigur mockup"
                objectFit="cover"
                className="h-full w-full lg:hidden"
            />
        </div>
    )
}
const BaigurImageThree = () => {
    return (
        <div className="h-full w-full">
            <StaticImage
                src={"../../../static/case-study/saigon-baigur/slider-3.png"}
                alt="Saigon Baigur mockup"
                objectFit="cover"
                className="h-full w-full"
            />
            <StaticImage
                src={
                    "../../../static/case-study/saigon-baigur/slider-3-mobile.png"
                }
                alt="Saigon Baigur mockup"
                objectFit="cover"
                className="h-full w-full lg:hidden"
            />
        </div>
    )
}
const BaigurImageFour = () => {
    return (
        <div className="h-full w-full">
            <StaticImage
                src={"../../../static/case-study/saigon-baigur/slider-4.png"}
                alt="Saigon Baigur mockup"
                objectFit="cover"
                className="h-full w-full"
            />
            <StaticImage
                src={
                    "../../../static/case-study/saigon-baigur/slider-4-mobile.png"
                }
                alt="Saigon Baigur mockup"
                objectFit="cover"
                className="h-full w-full lg:hidden"
            />
        </div>
    )
}
export { BaigurImageOne, BaigurImageTwo, BaigurImageThree, BaigurImageFour }
