import gsap from "gsap"
import ScrollTrigger from "gsap/dist/ScrollTrigger"
import React, { useEffect, useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"

if (typeof window !== "undefinded") {
    gsap.registerPlugin(ScrollTrigger)
}
function BaigurBanner({ className, children }) {
    const $wrapper = useRef(null)
    const $image = useRef(null)
    useEffect(() => {
        const mm = gsap.matchMedia($wrapper)
        mm.add("(min-width: 1024px)", () => {
            gsap.fromTo(
                $image.current,
                { yPercent: -5, scale: 1.1 },
                {
                    yPercent: 5,
                    scrollTrigger: {
                        trigger: $wrapper.current,
                        scrub: 1,
                        start: "top-=50% center",
                        end: "bottom center"
                    },
                    ease: "none"
                }
            )
        })

        return () => {
            mm.revert()
        }
    }, [])
    return (
        <div
            className={`relative ${className} aspect-[390/524] overflow-hidden lg:aspect-[1440/460] lg:h-auto lg:w-full`}
            ref={$wrapper}
        >
            <div className={`h-full w-full`} ref={$image}>
                {children}
            </div>
        </div>
    )
}

export default BaigurBanner
